<template>
  <div class="ddj-offer">
    <!-- <div class="main-Title bgff"><h2>上传记录</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="list-filter mb10">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="Country:" label-width="70px">
                <el-select
                  v-model="filter.country"
                  placeholder="Please select"
                  class="w100"
                  filterable
                >
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="OS:" label-width="30px">
                <el-select v-model="filter.os" placeholder="Please select OS" class="w100">
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.os"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="id:" label-width="20px">
                <el-input v-model="filter.id" placeholder="Please enter"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="Name:" label-width="60px">
                <el-input v-model="filter.name" placeholder="Please enter"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="数据包类型:" label-width="90px">
                <el-select
                  v-model="filter.dataSourceType"
                  placeholder="Please select OS"
                  class="w100"
                >
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.dataSourceType"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <el-form-item label-width="0px">
                <el-button
                  type="primary"
                  size="mini"
                  @click="getList('filter')"
                  :loading="loading.list"
                  >Search</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          class="w100"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column label="Country" prop="country"></el-table-column>
          <el-table-column label="OS" prop="os"></el-table-column>
          <el-table-column label="设备数" prop="deviceCount"></el-table-column>
          <el-table-column label="名称" prop="deviceName">
            <template slot-scope="scope">
              {{ scope.row.deviceName }}
              <i
                class="el-icon-edit"
                @click="updateDeviceName(scope.row.id, scope.row.deviceName)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status" align="center"></el-table-column>
          <el-table-column label="oss地址" prop="ossSavedUrl"></el-table-column>
          <el-table-column label="数据包类型" prop="dataSourceType" width="90px" align="center">
            <template slot-scope="scope">{{
              scope.row.dataSourceType | dataSourceTypeName(options.dataSourceType)
            }}</template>
          </el-table-column>
          <el-table-column
            label="更新"
            prop="isUpdate"
            align="center"
            width="60px"
          ></el-table-column>
          <el-table-column label="更新时间" prop="updateTime"></el-table-column>
          <el-table-column label="count_day" prop="countDay"></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
          <el-table-column label="操作" width="100" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="edit(scope.row.id)"
              ></el-button>
              <!--       <el-tooltip class="item" effect="dark" content="更新" placement="top">
                <el-button
                  type="primary"
                  icon="el-icon-refresh"
                  circle
                  size="mini"
                  @click="updata(scope.row.id, scope.row.isUpdate)"
                ></el-button>
              </el-tooltip> -->
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="del(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
      <el-dialog title="更新状态" :visible.sync="diallog.updata">
        <el-form ref="isUpdata" :model="isUpdataPost" :rules="rules" label-width="150px">
          <el-form-item label="ID" prop="id"
            ><el-input v-model="isUpdataPost.id" placeholder="请输入offer ID" :disabled="true"
          /></el-form-item>
          <el-form-item label="是否更新" prop="isUpdate">
            <el-select
              v-model="isUpdataPost.isUpdate"
              placeholder="Please select"
              class="mr10"
              style="width: 100%;"
            >
              <el-option
                v-for="item in options.isUpdate"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="diallog.updata = false">取 消</el-button>
          <el-button type="primary" @click="submitUpdata()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import ddjUploadListCon from '../../../controllers/DDJ/v3/data/uploadList';
  export default {
    name: 'ddjUploadList',
    ...ddjUploadListCon,
  };
</script>

<style></style>
